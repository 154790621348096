import { Box, Button, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import image from "../components/img/loginApps.webp";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const StaticErrorLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const version = useSelector((state) => parseInt(state.data.version, 10));
  const navigate = useNavigate();

  const setQueryParam = (key, value) => {
    queryParams.set(key, value);
    navigate({ search: queryParams.toString() });
  };
  return (
    <Box minH={"100vh"}>
      <Flex
        bgColor={"white"}
        minH={"100vh"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        p={5}
      >
        <div></div>
        <Grid placeItems={"center"} textAlign={"center"}>
          <Image src={image} maxW={"200px"} mb={5} />
          <Text userSelect={"none"} color={"#3D3F40"} fontSize={"16px"} fontWeight={700}>
            Sobat Belum Login
          </Text>
          <Text userSelect={"none"} mt={2} color={"#717375"} fontSize={"14px"} fontWeight={400}>
            Untuk mengakses fitur ini,
            <br /> login dulu ya sob
          </Text>
        </Grid>

        { version >= 112 && (
          <Button
            mt={7}
            borderRadius={"10px"}
            width={"100%"}
            bgColor={"#20929D"}
            color={"white"}
            onClick={() => setQueryParam('android', 'login')}
          >
            <Text userSelect={"none"}fontWeight={400} fontSize={"0.8em"}>
              Login
            </Text>
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default StaticErrorLogin;
