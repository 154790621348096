import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  Image,
  Skeleton,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { useState, useRef, useLayoutEffect } from "react";
import "../css/bottomSheet.css";
import { FaArrowRightLong, FaChevronUp } from "react-icons/fa6";
import staticMap from "./img/staticMap.webp";
import mapKalimantan from "./img/mapKalimantan.webp";
import mapSulawesi from "./img/mapSulawesi.webp";
import mapJawa from "./img/mapJawa.webp";
import mapSumatera from "./img/mapSumatera.webp";
import mapPapua from "./img/mapPapua.webp";

// Create a ref for the container

function BottomSheetMap({ slideUp, data, isDragging, setIsDragging, onClick }) {
  const [selectedArea, setSelectedArea] = useState("");
  const [slideClass, setSlideClass] = useState(slideUp);
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef(null);
  const imageSources = {
    1: mapPapua,
    3: mapKalimantan,
    4: mapSulawesi,
    2: mapJawa,
    5: mapSumatera,
  };

  useLayoutEffect(() => {
    if (containerRef.current && selectedArea.id) {
      // Find the selected item element by its ID
      const selectedItemElement = containerRef.current.querySelector(
        `#_${selectedArea.id}`
      );
      if (selectedItemElement) {
        // Scroll to the selected item's position within the container
        containerRef.current.scrollTo({
          top: selectedItemElement.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [selectedArea.id]);

  const handleUpClick = () => {
    setIsDragging(!isDragging);
  };

  const handleOnClick = (item) => {
    if (item) {
      setSlideClass("slideOut");
      onClick(item);
    }
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const handleClick = (param) => {
    if (typeof param === "object") {
      setSelectedArea(param);
    } else {
      const [id, name] = param.split("_");
      const combinedParam = {
        id: parseInt(id),
        wilayah: name,
      };
      setSelectedArea(combinedParam);
    }
  };

  const items = data.map((item, index) => (
    <Box
      bg={selectedArea.id === item.id ? "#21565A" : ""}
      borderRadius="md"
      position={"relative"}
      key={index}
      cursor="pointer"
      px={1}
      py={4}
      id={`_${item.id}`}
      onClick={() => handleClick(item)}
    >
      <Flex key={index} justify={"space-between"} alignItems={"center"}>
        <Text userSelect={"none"} fontSize="sm">
          {item.wilayah}
        </Text>
      </Flex>
    </Box>
  ));

  return (
    <div>
      <Box
        className={slideClass}
        borderTopRadius={40}
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        backgroundColor="#10494E"
        boxShadow="sm"
        px={5}
      >
        <Center>
          <Grid
            bgColor={"#1B7A83"}
            borderRadius={"10px"}
            placeItems={"center"}
            px={5}
            my={5}
            py={"1px"}
          >
            <Icon
              className={!isDragging ? "up" : "down"}
              as={FaChevronUp}
              fontSize={18}
              color={"#B5DBDE"}
              onClick={handleUpClick}
            />
          </Grid>
        </Center>

        <Center>
          <Box position={"relative"}>
            <Box display={!imageLoaded ? "none" : "grid"}>
              <Image
                maxHeight={"15vh"}
                src={
                  selectedArea.id ? imageSources[selectedArea.id] : staticMap
                }
                onLoad={handleImageLoad}
              />
            </Box>
            <Box display={imageLoaded ? "none" : "grid"}>
              <Skeleton
                height="100px"
                opacity={0.1}
                startColor="#B5DBDE"
                endColor="#1B7A83"
                borderRadius={20}
              />
            </Box>
            {data.map((item, index) => (
              <Box key={index}>
                <Box
                  position={"absolute"}
                  top={"25%"}
                  left={"0%"}
                  width={"70px"}
                  height={"20px"}
                  transform="rotate(50deg)"
                  className="btnClr"
                  display={ item.id === 5 ? "block" : "none"}
                  onClick={() => handleClick("5_SUMATERA")}
                ></Box>
                <Box
                  position={"absolute"}
                  top={"10%"}
                  left={"30%"}
                  width={"55px"}
                  height={"40px"}
                  className="btnClr"
                  display={ item.id === 3 ? "block" : "none"}
                  onClick={() => handleClick("3_KALIMANTAN")}
                ></Box>
                <Box
                  position={"absolute"}
                  top={"35%"}
                  left={"50%"}
                  width={"40px"}
                  height={"24px"}
                  className="btnClr"
                  transform="rotate(-70deg)"
                  display={ item.id === 4 ? "block" : "none"}
                  onClick={() => handleClick("4_SULAWESI")}
                ></Box>
                <Box
                  position={"absolute"}
                  top={"57%"}
                  right={"0%"}
                  width={"130px"}
                  height={"35px"}
                  transform="rotate(-20deg)"
                  className="btnClr"
                  display={ item.id === 1 ? "block" : "none"}
                  onClick={() => handleClick("1_INDONESIA TIMUR")}
                ></Box>
                <Box
                  position={"absolute"}
                  bottom={"10%"}
                  left={"25%"}
                  height={"20px"}
                  width={"50px"}
                  className="btnClr"
                  display={ item.id === 2 ? "block" : "none"}
                  onClick={() => handleClick("2_JAWA-BALI")}
                ></Box>
              </Box>
            ))}
          </Box>
        </Center>
        <Stack
          mt={5}
          backgroundColor="#10494E"
          color={"white"}
          px={3}
          className={isDragging ? "expandMap" : "halfMap"}
          ref={containerRef}
          overflowY={"auto"}
          divider={
            <StackDivider
              borderColor="#1B7A83"
              opacity={0.2}
              borderWidth={"0.5px"}
            />
          }
          spacing="0"
          position={"relative"}
        >
          {items}
        </Stack>
        <Button
          isDisabled={!selectedArea}
          mb={5}
          className={"submitBtn"}
          color={"white"}
          width={"100%"}
          height={"35px"}
          onClick={() => handleOnClick(selectedArea)}
        >
          Lanjut <Icon ml={2} fontSize={15} as={FaArrowRightLong} />
        </Button>
      </Box>
    </div>
  );
}

export default BottomSheetMap;
