import "./App.css";
import MobileOnlyContent from "./components/MobileOnlyContent";
import Link from "./link/linkRoutes";


function App() {
 

  return (
    <div className="App">
      <MobileOnlyContent>
        <Link />
      </MobileOnlyContent>
    </div>
  );
}

export default App;
