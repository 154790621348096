import {
  Box,
  Center,
  Flex,
  Grid,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../css/bottomSheet.css";
import { FaChevronRight } from "react-icons/fa6";
import { BiSearch } from "react-icons/bi";
import { FaChevronUp } from "react-icons/fa6";

function BottomSheetNormal({
  slideUp,
  data,
  isDragging,
  setIsDragging,
  onClick,
  propFilter,
}) {
  const [search, setSearch] = useState("");
  const [slideClass, setSlideClass] = useState('slideUp');
  const handleUpClick = () => {
    setIsDragging(!isDragging);
  };

  const handleSearch = (param) => {
    setSearch(param);
  };
  const handleOnClick = (item) => {
    setSlideClass("slideOut");
    onClick(item);
  };

  const filteredData = search
    ? data.filter((item) =>
        item[propFilter].toString().includes(search.toUpperCase())
      )
    : data;

  const items = filteredData.map((item, index) => (
    <Flex
      key={index}
      justify={"space-between"}
      alignItems={"center"}
      px={1}
      py={4}
      onClick={() => handleOnClick(item)}
    >
      <Text userSelect={"none"} fontSize="sm">{item[propFilter]}</Text>
      <Icon fontSize={15} color={"white"} as={FaChevronRight} />
    </Flex>
  ));

  return (
    <div>
      <Box
        className={ slideClass}
        borderTopRadius={40}
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        backgroundColor="#10494E"
        boxShadow="sm"
        px={5}
        pb={3}
      >
        <Center>
          <Grid
            bgColor={"#1B7A83"}
            borderRadius={"10px"}
            placeItems={"center"}
            px={5}
            my={5}
            py={"1px"}
          >
            <Icon
              className={!isDragging ? "up" : "down"}
              as={FaChevronUp}
              fontSize={18}
              color={"#B5DBDE"}
              onClick={handleUpClick}
            />
          </Grid>
        </Center>

        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={BiSearch} fontSize={15} color={"white"} />
          </InputLeftElement>
          <Input
            type="text"
            variant={"filled"}
            bgColor={"rgba(255, 255, 255, 0.07)"}
            placeholder="Cari"
            color={"white"}
            focusBorderColor="white"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </InputGroup>
        <Box
          className={isDragging ? "expand" : "half"}
          mt={5}
          overflowY={"auto"}
          backgroundColor="#10494E"
          color={"white"}
          px={3}
        >
          <Stack
            divider={
              <StackDivider
                borderColor="#1B7A83"
                opacity={0.2}
                borderWidth={"0.5px"}
              />
            }
            spacing="0"
          >
            {items}
          </Stack>
        </Box>
      </Box>
    </div>
  );
}

export default BottomSheetNormal;
