import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Icon,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useState, useLayoutEffect } from "react";
import Navbar from "../components/Navbar";
import WheelPicker from "react-simple-wheel-picker";
import MainCarousel from "../components/MainCarousel";
import axios from "axios";
import "../css/Merk.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowRightLong } from "react-icons/fa6";
import { setMerk } from "../reducers/data";
import StaticError from "../components/StaticError";
import StaticLoadingMerk from "../components/StaticLoadingMerk";
import StaticErrorLogin from "../components/StaticErrorLogin";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Merk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listMerk, setListMerk] = useState([]);
  const [selectedMerk, setSelectedMerk] = useState(listMerk[0]);
  const [currentId, setCurrentId] = useState(2);
  const [currentImage, setCurrentImage] = useState([]);
  const [changeVisible, setChangeVisible] = useState(false);
  const [listMerkImage, setListMerkImage] = useState([]);
  const [overlapImage, setOverlapImage] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const token = useSelector((state) => state.data.token);

  const {
    data: merkData,
    isError,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["merk"],
    queryFn: async () => {
      var params = new URLSearchParams();
      params.append("limit", 100);
      var request = {
        params: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios.get(
        baseUrl + "/produk/v4/master/merk",
        request
      );
      return response.data.data;
    },
    refetchOnWindowFocus: false,
  });

  useLayoutEffect(() => {
    const newMerk = merkData?.map((item) => ({
      id: item?.id?.toString() || "",
      value: item?.merk || "",
      image: item?.merk_photo || "",
    }));
    const merkImage = merkData?.map((item) => ({
      id: item?.id?.toString() || "",
      image: item?.merk_photo || "",
    }));
    if (newMerk?.length > 0) {
      setListMerkImage(merkImage);
      setListMerk(newMerk);
      setCurrentImage(newMerk[0]?.image || "");
    }
  }, [merkData]);

  if (isFetching) {
    return <StaticLoadingMerk />;
  }
  if (isError) {
    if (error?.response?.status === 403) {
      // Render the custom UI for 403 Forbidden error
      return <StaticErrorLogin />;
    } else {
      // Render a generic error UI
      return <StaticError />;
    }
  }
  const onSelectedMerkChange = (data) => {
    setCurrentId(data.id);
    setCurrentImage(data.image);
    const updatedListMerk = listMerk.map((item) => ({
      ...item,
    }));
    setListMerk(updatedListMerk);
  };

  const onWheelMerkChange = (data) => {
    setSelectedMerk(data);
  };
  const goBack = () => {
    navigate("/");
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
    console.log(imageLoaded);
  };
  const goToTipe = (param) => {
    const combinedData = {
      id: param.id,
      value: param.value,
      image: currentImage,
    };

    dispatch(setMerk(combinedData));

    setChangeVisible(!changeVisible);
    setTimeout(() => {
      setOverlapImage(!overlapImage);
    }, 500);
    setTimeout(() => {
      navigate("/tipe");
    }, 1000);
  };
  return (
    <Box
      bgColor={"#1B7A83"}
      minH={"100vh"}
      overflowX={"hidden"}
      overflowY={"hidden"}
    >
      <Flex
        flexDirection={"column"}
        minH={"100vh"}
        justifyContent={"space-between"}
      >
        <Navbar
          val={"Pilih Merek"}
          onClick={() => goBack()}
          colorIcon={"white"}
        />
        <Flex
          flexDirection={"column"}
          minH={"70vh"}
          justifyContent={"space-between"}
          position={"relative"} // Ensure the container is positioned
        >
          <Box className={overlapImage ? "roll-out " : ""} zIndex={2}>
            <MainCarousel
              onImageLoad={handleImageLoad}
              imageLoaded={imageLoaded}
              selectedMerk={selectedMerk}
              listMerkImage={listMerkImage}
              onSelectedMerkChange={onSelectedMerkChange}
            />
          </Box>

          <Card
            borderRadius={800}
            className={changeVisible ? "fadeout circle" : "circle"}
            id="backCircle"
            position={"relative"}
          ></Card>
          <Center className={changeVisible ? "fadeout " : ""} zIndex={1}>
            {listMerk.length === 0 ? (
              <></>
            ) : (
              <Box>
                {!imageLoaded ? (
                  <Stack
                  gap={5}
                    style={{
                      transform: "translateY(-26%)", // Adjust the translateY value as needed
                    }}
                  >
                    <Skeleton
                      mt={40}
                      w={"100px"}
                      borderRadius={20}
                      startColor="#B5DBDE"
                      alignSelf={"center"}
                      endColor="#1B7A83"
                      height="15px"
                    />
                    <Skeleton
                      w={"50px"}
                      startColor="#B5DBDE"
                      borderRadius={20}
                      endColor="#1B7A83"
                      alignSelf={"center"}
                      height="15px"
                    />
                    <Skeleton
                      w={"100px"}
                      borderRadius={20}
                      startColor="#B5DBDE"
                      alignSelf={"center"}
                      endColor="#1B7A83"
                      height="15px"
                    />
                    <Skeleton
                      w={"50px"}
                      startColor="#B5DBDE"
                      borderRadius={20}
                      endColor="#1B7A83"
                      alignSelf={"center"}
                      height="15px"
                    />
                  </Stack>
                ) : (
                  <Box className="custom-wheel-picker-container">
                    <Box
                      className={
                        changeVisible ? "" : "custom-wheel-picker-overlay-top"
                      }
                    ></Box>
                    <Box
                      position={"absolute"}
                      minH={"40px"}
                      minW={"150px"}
                      bgColor={"#1B7A83"}
                      borderRadius={20}
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    ></Box>
                    <WheelPicker
                      data={listMerk}
                      height={200}
                      selectedID={currentId}
                      width={300}
                      idName="merks"
                      itemHeight={47}
                      onChange={onWheelMerkChange}
                      color="#ffffff75"
                      activeColor="#ffffffc4"
                      backgroundColor="none"
                      focusColor="none"
                      shadowColor="none"
                    />
                    <Box
                      className={
                        changeVisible ? "" : "custom-wheel-picker-overlay-bot"
                      }
                    ></Box>
                  </Box>
                )}
              </Box>
            )}
          </Center>
        </Flex>

        {!imageLoaded ? (
          <Grid placeItems={"center"}>
            <Skeleton
              w={"90%"}
              mb={5}
              mx={5}
              startColor="#B5DBDE"
              endColor="#1B7A83"
              alignSelf={"center"}
              height="40px"
            />
          </Grid>
        ) : (
          <Box p={2}>
            <Button
              mb={5}
              mx={5}
              onClick={() => {
                goToTipe(selectedMerk);
              }}
              pointerEvents={changeVisible && "none"}
              className={changeVisible ? "fadeout submitBtn" : "submitBtn"}
              color={"white"}
              width={"90%"}
            >
              Lanjut <Icon ml={2} fontSize={15} as={FaArrowRightLong} />
            </Button>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default Merk;
