import React, { useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "../view/Home";
import Merk from "../view/Merk";
import Tipe from "../view/Tipe";
import Varian from "../view/Varian";
import Tahun from "../view/Tahun";
import Wilayah from "../view/Wilayah";
import Result from "../view/Result";
import Update from "../view/Update";
import ProtectedRoute from "./protectedRoute";
import { useSelector, useDispatch } from "react-redux";
import { setToken, setVersion } from "../reducers/data";
import Ownership from "../view/Ownership";

const LinkRoutes = () => {
  const isAuthenticated = useSelector((state) => state.data.token !== null);
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const version = query.get("version");
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (token) {
      dispatch(setToken(token));
      dispatch(setVersion(version));
    }
  }, [dispatch]);
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} token={token} />
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/merk" element={<Merk />} />
            <Route path="/tipe" element={<Tipe />} />
            <Route path="/varian" element={<Varian />} />
            <Route path="/tahun" element={<Tahun />} />
            <Route path="/wilayah" element={<Wilayah />} />
            <Route path="/ownership" element={<Ownership />} />
            <Route path="/result" element={<Result />} />
          </Route>
          <Route path="/update" element={<Update />} />
          <Route path="*" element={<Update />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default LinkRoutes;
