import { Box, Center, Flex, Icon, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineLine } from 'react-icons/ai'

const StaticLoading = () => {
  return (
    <Flex
        bgColor={"#1B7A83"}
        flexDirection={"column"}
        minH={"100vh"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex
          border={"2px solid white"}
          mt={"20%"}
          borderRadius={"40px"}
          overflow={"hidden"}
          alignItems={"center"}
          textAlign={"center"}
          bgColor={"#B5DBDE"}
          minH={"150px"}
          minW={"290px"}
        >
          <Box h={"100px"} w={"100%"}></Box>
        </Flex>
        <Box
          className="slideUp"
          borderTopRadius={40}
          position="fixed"
          bottom="0"
          left="0"
          width="100%"
          backgroundColor="#10494E"
          boxShadow="sm"
          px={5}
        >
          <Center>
            <Icon as={AiOutlineLine} fontSize={50} color={"#1B7A83"} />
          </Center>
          <Box
            maxH={"40vh"}
            overflow={"hidden"}
            mt={5}
            overflowY={"auto"}
            backgroundColor="#10494E"
            color={"white"}
            px={3}
          >
            <Stack spacing="3">
              {[...Array(10)].map((_, index) => (
                <Flex key={index}>
                  <Skeleton
                    w={"100%"}
                    mr={2}
                    borderRadius={5}
                    startColor="#B5DBDE"
                    alignSelf={"center"}
                    endColor="#1B7A83"
                    height="15px"
                  />
                  <SkeletonCircle
                    border={"2px solid white"}
                    borderRadius={140}
                    opacity={1}
                    startColor="#B5DBDE"
                    endColor="#1B7A83"
                    placeItems={"center"}
                    size="5"
                  />
                </Flex>
              ))}
            </Stack>
          </Box>
        </Box>
      </Flex>
  )
}

export default StaticLoading
