import React, { useState, useLayoutEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../css/Carousel.css";
import { Box, Grid, Icon, Image, SkeletonCircle } from "@chakra-ui/react";
import { RiEBikeLine } from "react-icons/ri";
import "swiper/css/effect-creative";
const imageBaseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

function MainCarousel({
  selectedMerk,
  onSelectedMerkChange,
  listMerkImage,
  onImageLoad,
  imageLoaded,
}) {
  const frameData = listMerkImage.map((item) => ({
    id: item.id,
    image: item.image.filter((photo) => photo.flag === "circle"),
  }));

  useLayoutEffect(() => {
    const index = listMerkImage.findIndex(
      (item) => item?.id === selectedMerk?.id
    );
    // If the index is found, set it as the active slide
    console.log(imageLoaded);
    if (index !== -1) {
      if (imageLoaded) {
        const swiper = document.querySelector(".mySwiper").swiper;
        swiper.slideTo(index);
      }
    }
  }, [selectedMerk, listMerkImage]);

  const swiperRef = useRef(null);

  const [isTouchOnEdge, setIsTouchOnEdge] = useState(false);

  const handleTouchMove = (swiper, event) => {
    const touchX = event.clientX;

    // Check if touch is on the edge
    const isOnEdge = touchX <= 10 || touchX >= window.innerWidth - 10;

    setIsTouchOnEdge(isOnEdge);

    // Disable touch move if the touch is on the edge
    if (isOnEdge) {
      swiper.allowTouchMove = false;
    } else {
      swiper.allowTouchMove = true;
    }
  };
  const handleTouchEnd = (swiper) => {
    setIsTouchOnEdge(false);
    swiper.allowTouchMove = true;
  };
  const handleImageLoad = () => {
    onImageLoad();
  };
  const handleSlideChange = () => {
    setIsTouchOnEdge(false);
    const activeSlide = listMerkImage[swiperRef.current.activeIndex];

    onSelectedMerkChange({
      id: activeSlide.id,
      value: activeSlide.value,
      image: activeSlide.image,
    });

    // Trigger the animation on the current slide
    const slideElements = document.querySelectorAll(".swiper-slide");

    slideElements.forEach((slide, index) => {
      if (index === swiperRef.current.activeIndex) {
        slide.classList.add("animate-slide");
      }
    });
  };

  return (
    <Box placeContent={"center"}>
      <Swiper
        slidesPerView={1}
        loop={false}
        noSwiping={true}
        noSwipingClass="swiper-no-swiping"
        touchReleaseOnEdges={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={handleSlideChange}
        navigation={false}
        className="mySwiper"
        threshold={0}
        onTouchMove={(swiper, event) => handleTouchMove(swiper, event)}
        onTouchEnd={(swiper) => handleTouchEnd(swiper)}
        creativeEffect={{
          prev: {
            translate: ["-110%", 120, 0],
          },
          next: {
            translate: ["100%", 120, 0],
          },
        }}
        effect={"creative"}
        modules={[EffectCreative]}
      >
        {frameData.map((item) => (
          <SwiperSlide
            className={!imageLoaded && "swiper-no-swiping"}
            key={item.id}
            style={{ maxHeight: "80vh", width: "100%", zIndex: 9999 }}
          >
            {item.image.map((photo, index) => (
              <Box key={index} style={{ width: "100%", position: "relative" }}>
                <Box
                  display={!imageLoaded ? "none" : "grid"}
                  placeItems={"center"}
                  textAlign={"-webkit-center"}
                  style={{
                    transform: "translateY(-10%)",
                  }}
                >
                  <Image
                    filter={"drop-shadow(10px 10px 10px #00000040)"}
                    src={imageBaseUrl + photo.photo}
                    alt={`Slide ${item.id}`}
                    onLoad={handleImageLoad}
                    style={{
                      objectFit: "contain",
                      maxWidth: "400px",
                      maxHeight: "200px",
                    }}
                  />
                </Box>
                <Grid
                  display={imageLoaded ? "none" : "grid"}
                  placeItems={"center"}
                  style={{
                    transform: "translateY(-10%)",
                  }}
                >
                  <SkeletonCircle
                    w={"180px"}
                    h={"180px"}
                    opacity={1}
                    startColor="#B5DBDE"
                    endColor="#1B7A83"
                  />
                </Grid>
                <Box
                  display={photo.photo ? "none" : "grid"}
                  border={"2px solid white"}
                  borderRadius={140}
                  mt={-14}
                  w={"180px"}
                  h={"180px"}
                  placeItems={"center"}
                  bgColor={"#B5DBDE"}
                  p={3}
                  filter={"drop-shadow(10px 10px 10px #00000040)"}
                >
                  <Icon color={"#1B7A83"} fontSize={120} as={RiEBikeLine} />
                </Box>
              </Box>
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default MainCarousel;
