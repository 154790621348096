import { Card, Flex, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react'
import React from 'react'

const StaticLoadingMerk = () => {
  return (
    <Flex
        className="fadein"
        bgColor={"#1B7A83"}
        flexDirection={"column"}
        minH={"100vh"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Card
          borderRadius={800}
          className={"circle"}
          id="backCircle"
          position={"relative"}
        ></Card>
        <div></div>
        <Stack gap={5}>
          <SkeletonCircle
            border={"2px solid white"}
            borderRadius={140}
            transform={"translateY(20%)"}
            w={"180px"}
            h={"180px"}
            opacity={1}
            startColor="#B5DBDE"
            endColor="#1B7A83"
            placeItems={"center"}
            size="10"
          />

          <Skeleton
            mt={40}
            w={"100px"}
            borderRadius={20}
            startColor="#B5DBDE"
            alignSelf={"center"}
            endColor="#1B7A83"
            height="15px"
          />
          <Skeleton
            w={"50px"}
            startColor="#B5DBDE"
            borderRadius={20}
            endColor="#1B7A83"
            alignSelf={"center"}
            height="15px"
          />
          <Skeleton
            w={"100px"}
            borderRadius={20}
            startColor="#B5DBDE"
            alignSelf={"center"}
            endColor="#1B7A83"
            height="15px"
          />
          <Skeleton
            w={"50px"}
            startColor="#B5DBDE"
            borderRadius={20}
            endColor="#1B7A83"
            alignSelf={"center"}
            height="15px"
          />
        </Stack>
        <Skeleton
          w={"90%"}
          mb={5}
          mx={5}
          startColor="#B5DBDE"
          endColor="#1B7A83"
          alignSelf={"center"}
          height="40px"
        />
      </Flex>
  )
}

export default StaticLoadingMerk
