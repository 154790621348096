import { Button, Flex, Grid, Image, Text } from "@chakra-ui/react";
import errorImage from "../components/img/errorImage.webp";
import React from "react";
import { useNavigate } from "react-router-dom";

const StaticError = () => {
  const navigate = useNavigate();

  return (
    <Flex
      bgColor={"#1B7A83"}
      minH={"100vh"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      p={5}
    >
      <div></div>
      <Grid placeItems={"center"} textAlign={"center"}>
        <Image src={errorImage} mb={5} />
        <Text
          userSelect={"none"}
          color={"white"}
          fontSize={"14px"}
          fontWeight={600}
        >
          Sepertinya ada kendala, silahkan coba lagi atau kembali ke halaman
          awal
        </Text>
      </Grid>

      <Button
        mt={2}
        borderRadius={"10px"}
        width={"100%"}
        bgColor={"#10494E"}
        color={"white"}
        onClick={() => navigate("/")}
      >
        <Text userSelect={"none"} fontWeight={400} fontSize={"0.8em"}>
          Kembali ke halaman awal
        </Text>
      </Button>
    </Flex>
  );
};

export default StaticError;
