import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    token: null,
    version: null,
    merk: [],
    tahun: [],
    tipe: [],
    varian: [],
    wilayah: [],
    ownership: [],
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setMerk: (state, action) => {
      state.merk = action.payload;
    },
    setTahun: (state, action) => {
      state.tahun = action.payload;
    },
    setTipe: (state, action) => {
      state.tipe = action.payload;
    },
    setVarian: (state, action) => {
      state.varian = action.payload;
    },
    setWilayah: (state, action) => {
      state.wilayah = action.payload;
    },
    setOwnership: (state, action) => {
      state.ownership = action.payload;
    },
  },
});

export const { setToken,setVersion, setMerk, setTahun, setTipe, setVarian, setWilayah,setOwnership } =
  dataSlice.actions;

export const dataReducer = dataSlice.reducer;
