import { Box, Center, Flex, Icon, Image, Text } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import React, { useState, useLayoutEffect } from "react";
import "../css/Tipe.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import BottomSheet from "../components/BottomSheet";
import { useSelector, useDispatch } from "react-redux";
import { setVarian } from "../reducers/data";
import { RiEBikeLine } from "react-icons/ri";
import StaticError from "../components/StaticError";
import StaticLoading from "../components/StaticLoading";
import StaticErrorLogin from "../components/StaticErrorLogin";
const baseUrl = process.env.REACT_APP_BASE_URL;
const imageBaseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

const Varian = () => {
  const navigate = useNavigate();
  const dataName = `${useSelector(
    (state) => state.data.merk.value
  )} ${useSelector((state) => state.data.tipe.type)}`;
  const tipeImage = useSelector((state) =>
    state.data.tipe?.type_photo?.filter((image) => image.flag === "photo")
  );
  const merkImage = useSelector((state) =>
    state.data.merk?.image?.filter((image) => image.flag === "square")
  );

  const dispatch = useDispatch();
  const id = useSelector((state) => state.data.tipe.id);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [image, setImage] = useState([]);
  const token = useSelector((state) => state.data.token);

  const {
    data: varianData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["varian"],
    queryFn: async () => {
      var params = new URLSearchParams();
      params.append("id_mst_type", id);
      var request = {
        params: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios.get(
        baseUrl + "/produk/v4/master/varian",
        request
      );
      return response.data.data;
    },
    refetchOnWindowFocus: false,
  });
  useLayoutEffect(() => {
    if (tipeImage && tipeImage?.length !== 0) {
      if (tipeImage[0].photo !== "") {
        setImage(imageBaseUrl + tipeImage[0].photo);
      }
    } else if (merkImage && merkImage?.length !== 0) {
      if (merkImage[0].photo !== "") {
        setImage(imageBaseUrl + merkImage[0].photo);
      }
    }
  }, []);

  if (isLoading) {
    return <StaticLoading />;
  }
  if (isError) {
    if (error?.response?.status === 403) {
      // Render the custom UI for 403 Forbidden error
      return <StaticErrorLogin />;
    } else {
      // Render a generic error UI
      return <StaticError />;
    }
  }

  const goBack = () => {
    navigate('/tipe');
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const goToTahun = (param) => {
    dispatch(setVarian(param));
    setIsChange(!isChange);
    setTimeout(() => {
      navigate("/tahun");
    }, 500);
  };
  return (
    <Box bgColor={"#1B7A83"} minH={"100vh"} overflowX={"hidden"}>
      <Navbar
        val={"Pilih Varian"}
        onClick={() => goBack()}
        colorIcon={"white"}
      />
      <Center zIndex={99}>
        <Box className={isDragging ? "smallSize" : "normalSize"}>
          <Flex
            mt={!imageLoaded ? 2 : -2}
            flexDirection={"column"}
            className={isChange ? "ballout" : "ball"}
          >
            <Flex
              display={imageLoaded ? "none" : "flex"}
              border={"2px solid white"}
              borderRadius={"40px 40px 0px 0px"}
              overflow={"hidden"}
              alignItems={"center"}
              textAlign={"center"}
              bgColor={"#B5DBDE"}
              minH={"150px"}
              minW={"290px"}
            >
              <Box w={"100%"}>
                <Icon fontSize={100} as={RiEBikeLine} />
              </Box>
            </Flex>
            <Flex textAlign={"center"}>
              <Image
                pointerEvents={"none"}
                userSelect={"none"}
                display={!imageLoaded && "none"}
                src={image}
                boxSize="290px"
                objectFit="cover"
                h="100%"
                onLoad={handleImageLoad}
              />
            </Flex>
            <Box
              display={!imageLoaded ? "none" : "flex"}
              p={1}
              zIndex={100}
              justifyContent={"center"}
              textAlign={"center"}
              borderRadius={"0px 0px 40px 40px "}
              h={"52px"}
              alignItems={"center"}
              maxW={"290px"}
              mt={"-18%"}
            >
              <Text
                userSelect={"none"}
                color={"#10494E"}
                fontWeight={"bold"}
                fontSize={"14px"}
              >
                {dataName}
              </Text>
            </Box>
            <Box
              display={imageLoaded ? "none" : "flex"}
              justifyContent={"center"}
              textAlign={"center"}
              bgColor={"lightgrey"}
              alignItems={"center"}
              border={"2px solid white"}
              p={1}
              zIndex={100}
              borderRadius={"0px 0px 40px 40px "}
              h={"52px"}
            >
              <Text userSelect={"none"} color={"#10494E"} fontWeight={"bold"}>
                {dataName}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Center>

      <BottomSheet
        slideUp={!isLoading && "slideUp"}
        data={varianData}
        propFilter={"varian"}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        onClick={(val) => goToTahun(val)}
      />
    </Box>
  );
};

export default Varian;
