import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import Navbar from "../components/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { PiShareFatLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useState, useLayoutEffect } from "react";
import moment from "moment";
import axios from "axios";
import drivingVespa from "../components/img/drivingVespa.gif";
import loadBg from "../components/img/bgLoadRes.webp";
import { HiArrowLongRight } from "react-icons/hi2";
import { RiEBikeLine } from "react-icons/ri";
import { FaPlusMinus } from "react-icons/fa6";
import imageDial from "../components/img/dialogImage.webp";
import "../css/Result.css";
import "moment/locale/id";
import StaticError from "../components/StaticError";
import StaticErrorLogin from "../components/StaticErrorLogin";
import audio from "../components/sound/sfx.mp3";
const baseUrl = process.env.REACT_APP_BASE_URL;
const imageBaseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

const Result = () => {
  const tahun = useSelector((state) => state.data.tahun);
  const wilayah = useSelector((state) => state.data.wilayah);
  const token = useSelector((state) => state.data.token);
  const version = useSelector((state) => parseInt(state.data.version, 10));
  const owner = useSelector((state) => state.data.ownership)
  const tipeImage = useSelector((state) =>
    state.data.tipe.type_photo?.filter((image) => image.flag === "result")
  );
  const merkCircleImage = useSelector((state) =>
    state.data.merk.image?.filter((image) => image.flag === "circle")
  );
  const [harga, setHarga] = useState("");
  const [hargaOtr, setHargaOtr] = useState("");

  const [image, setImage] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const currentYear = new Date().getFullYear();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [dateUpdate, setDateUpdate] = useState(null);
  moment.locale("id");

  const {
    data: resultData,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["result"],
    queryFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      var params = new URLSearchParams();
      params.append("id_mst_varian", tahun.id);
      params.append("id_mst_wilayah", wilayah.id);
      params.append('ownership', owner)

      var request = {
        params: params,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios.get(
        baseUrl + "/produk/v4/master/harga_pasar",
        request
      );
      return response.data.data;
    },
    throwOnError: (error) => error.response?.status === 403,
    refetchOnWindowFocus: false,
  });

  useLayoutEffect(() => {
    if (resultData) {
      const date = moment(resultData?.updated_at);
      const formattedDate = date.format("D MMMM YYYY");
      setDateUpdate(formattedDate);
      if (
        resultData.harga_bekas_dasar === 0 ||
        resultData.harga_bekas_tertinggi === 0
      ) {
        setHarga("Belum Tersedia");
      }
      if (resultData.harga_bekas_dasar === resultData.harga_bekas_tertinggi) {
        const formattedHargaDasar = new Intl.NumberFormat("de-DE").format(
          resultData.harga_bekas_dasar
        );
        setHarga("Rp." + formattedHargaDasar);
      } else {
        const formattedHargaDasar = new Intl.NumberFormat("de-DE").format(
          resultData.harga_bekas_dasar
        );
        const formattedHargaTertinggi = new Intl.NumberFormat("de-DE").format(
          resultData.harga_bekas_tertinggi
        );
        setHarga(
          "Rp." + formattedHargaDasar + " - Rp." + formattedHargaTertinggi
        );
      }
      if (resultData.harga_otr) {
        const formattedHargaOtr = new Intl.NumberFormat("de-DE").format(
          resultData.harga_otr
        );
        setHargaOtr("Rp." + formattedHargaOtr);
      } else {
        setHargaOtr("Tidak Tersedia");
      }
      if (tipeImage?.length !== 0) {
        if (tipeImage[0].photo !== "") {
          setImage(imageBaseUrl + tipeImage[0].photo);
        }
      } else {
        if (merkCircleImage[0].photo !== "") {
          setImage(imageBaseUrl + merkCircleImage[0].photo);
        }
      }
    }
  }, [resultData]);

  if (isFetching) {
    return (
      <Flex
        bgColor={"#1B7A83"}
        minH={"100vh"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image
          pointerEvents={"none"}
          userSelect={"none"}
          src={loadBg}
          mt={-9}
          w={"300px"}
          position={"fixed"}
          zIndex={0}
        />
        <Image
          pointerEvents={"none"}
          userSelect={"none"}
          src={drivingVespa}
          w={"80%"}
          mb={5}
          zIndex={1}
        />
        <Text
          userSelect={"none"}
          color={"white"}
          fontSize={"18px"}
          fontWeight={700}
          zIndex={1}
        >
          Tunggu sebentar ya sob <span className="wave">.</span>
          <span className="wave1">.</span>
          <span className="wave2">.</span>
        </Text>
      </Flex>
    );
  }
  if (isError) {
    if (error?.response?.status === 403) {
      // Render the custom UI for 403 Forbidden error
      return <StaticErrorLogin />;
    } else {
      // Render a generic error UI
      return <StaticError />;
    }
  }
  const handleImageLoad = () => {
    setImageLoaded(true);
    new Audio(audio).play();
  };
  const setQueryParam = (key, value) => {
    queryParams.set(key, value);

    // Update the URL with the new query parameter
    if (version >= 112) {
      navigate({ search: queryParams.toString() });
    } else {
      navigate("/update");
    }
  };
  const goBack = () => {
    navigate("/merk");
  };
  return (
    <Box
      bgColor={"#1B7A83"}
      className="resultClass"
      style={{
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <Navbar
        val={"Perkiraan harga motor"}
        onClick={onOpen}
        colorIcon={"white"}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent mx={"10px"}>
          <AlertDialogCloseButton />

          <AlertDialogBody mt={5}>
            <Center>
              <Image
                pointerEvents={"none"}
                userSelect={"none"}
                src={imageDial}
                width={"100px"}
              />
            </Center>
            <Text
              userSelect={"none"}
              textAlign={"center"}
              fontSize={"14px"}
              color={"#1B7A83"}
              fontWeight={700}
            >
              Sobat yakin ingin memulai cek Harga Pasar lagi?
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter px={3}>
            <Button
              border={"1px solid #20929D"}
              ref={cancelRef}
              fontSize={"12px"}
              width={"100%"}
              color={"#20929D"}
              onClick={onClose}
            >
              Batal
            </Button>
            <Button
              onClick={() => goBack()}
              bgColor={"#20929D"}
              width={"100%"}
              color="white"
              fontSize={"12px"}
              ml={3}
            >
              Oke
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Grid
        bgColor={"#1B7A83"}
        minH={"40vh"}
        placeItems={"center"}
        overflowX={"hidden"}
        overflowY={"hidden"}
        className="slideInTop"
      >
        <Grid placeItems={"center"} width={"300px"}>
          <Box position={"relative"} zIndex={2}>
            <Box display={!imageLoaded ? "none" : "grid"}>
              <Image
                pointerEvents={"none"}
                userSelect={"none"}
                src={image}
                objectFit={"contain"}
                className="slideRight"
                filter={"drop-shadow(10px 10px 10px #00000040)"}
                onLoad={handleImageLoad}
              />
            </Box>
            <Box
              display={imageLoaded ? "none" : "grid"}
              border={"2px solid white"}
              borderRadius={90}
              p={3}
              className="slideRight"
              filter={"drop-shadow(10px 10px 10px #00000040)"}
            >
              <Icon color={"white"} fontSize={100} as={RiEBikeLine} />
            </Box>
          </Box>
          {/* <Grid mb={4} placeItems={"center"}>
            <Image src={logoSimotor} />
          </Grid> */}
        </Grid>
      </Grid>
      <Flex
        bgColor={"#10494E"}
        minH={"60vh"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        overflowX={"hidden"}
        overflowY={"hidden"}
        className="slideIn"
        p={3}
      >
        <Box>
          <Card
            className="backForthAnim"
            borderRadius={"16px"}
            border={"2px solid white"}
            p={3}
          >
            <Text userSelect={"none"} fontSize={"0.7em"} color={"#10494E"}>
              Harga Motor Bekas
            </Text>
            <Text
              userSelect={"none"}
              fontSize={"0.8em"}
              color={"#10494E"}
              fontWeight={"700"}
            >
              {harga}
            </Text>
          </Card>
          <Flex my={3}>
            <Box
              background={"#20929D"}
              borderRadius={"16px"}
              border={"2px solid white"}
              width={"100%"}
              className="backForthAnim2"
              p={3}
              mr={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Harga Motor Baru {"(" + currentYear + ")"}
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                display={"flex"}
                alignItems={"center"}
                color={"white"}
                fontWeight={"700"}
              >
                {hargaOtr !== "Tidak Tersedia" && (
                  <Icon as={FaPlusMinus} mr={1} />
                )}
                {hargaOtr}
              </Text>
            </Box>

            <Card
              background={"#20929D"}
              borderRadius={"16px"}
              width={"100%"}
              p={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Merek
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                color={"white"}
                fontWeight={"700"}
              >
                {resultData?.merk}
              </Text>
            </Card>
          </Flex>
          <Flex my={3}>
            <Card
              background={"#20929D"}
              borderRadius={"16px"}
              width={"100%"}
              p={3}
              mr={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Tipe
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                color={"white"}
                fontWeight={"700"}
              >
                {resultData?.type}
              </Text>
            </Card>
            <Card
              background={"#20929D"}
              borderRadius={"16px"}
              width={"100%"}
              p={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Varian
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                color={"white"}
                fontWeight={"700"}
              >
                {resultData?.varian}
              </Text>
            </Card>
          </Flex>
          <Flex my={3}>
            <Card
              background={"#20929D"}
              borderRadius={"16px"}
              width={"20%"}
              p={3}
              mr={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Tahun
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                color={"white"}
                fontWeight={"700"}
              >
                {resultData?.tahun}
              </Text>
            </Card>
            <Card
              background={"#20929D"}
              borderRadius={"16px"}
              width={"80%"}
              p={3}
            >
              <Text userSelect={"none"} fontSize={"0.7em"} color={"white"}>
                Wilayah
              </Text>
              <Text
                userSelect={"none"}
                fontSize={"0.8em"}
                color={"white"}
                fontWeight={"700"}
              >
                {resultData?.wilayah}
              </Text>
            </Card>
          </Flex>
          <Text
            userSelect={"none"}
            textAlign={"center"}
            color={"white"}
            fontSize={"12px"}
          >
            Diperbaharui pada {dateUpdate}
          </Text>
          <Button
            mt={2}
            className="btnShare"
            borderRadius={"10px"}
            width={"100%"}
            color={"white"}
            onClick={() => setQueryParam("android", "share")}
          >
            <Text userSelect={"none"} fontWeight={400} fontSize={"0.8em"}>
              {" "}
              Bagikan
            </Text>
            <Icon ml={2} fontSize={15} as={PiShareFatLight} />
          </Button>
        </Box>

        {/* <Flex
          color={"white"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() =>
            window.location.replace(
              "https://simotor.devlmu.com/detail-iklan/afaefaef-41912"
            )
          }
        >
          <Text  userSelect={"none"} fontSize={"0.8em"}>Lihat Rekomendasi</Text>
          <Icon ml={2} fontSize={15} as={HiArrowLongRight} />
        </Flex> */}
      </Flex>
    </Box>
  );
};

export default Result;
