import React, { useState } from "react";
import Milik from "../components/img/cekMilik.webp";
import "../css/Ownership.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOwnership } from "../reducers/data";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import Navbar from "../components/Navbar";

const Ownership = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slideClass, setSlideClass] = useState("slideUp");

  const goToResult = (param) => {
    dispatch(setOwnership(param));
    setSlideClass("slideOut");
    setTimeout(() => {
      navigate("/result");
    }, 500);
  };
  const goBack = () => {
    navigate("/wilayah");
  };
  return (
    <Box height={"100vh"}>
      <Navbar onClick={() => goBack()} colorIcon={"white"} />
      <Box
        className={slideClass}
        p={3}
        backgroundColor="#10494E"
        display={"flex"}
        borderTopRadius={40}
        height={"90vh"}
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
      >
        <Box
          className="d-flex"
          style={{ justifyContent: "center", color: "white" }}
        >
          <Box
            py={2}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Image
              pointerEvents={"none"}
              userSelect={"none"}
              src={Milik}
              w={"70%"}
            />
            <Text>Sobat melakukan cek Harga Pasar untuk motor siapa ?</Text>
            <Box className="d-flex" p={3} flexDirection={"column"}>
              <Button
                style={{
                  color: "white",
                  border: "1px solid #20929D",
                  width: "100%",
                  justifyContent: "left",
                  borderRadius: "16px",
                  backgroundColor: "#20929D33",
                }}
                p={7}
                mb={4}
                onClick={() => goToResult(1)}
              >
                Milik Sendiri
              </Button>
              <Button
                style={{
                  color: "white",
                  border: "1px solid #20929D",
                  width: "100%",
                  justifyContent: "left",
                  borderRadius: "16px",
                  backgroundColor: "transparent",
                }}
                mb={4}
                p={7}
                onClick={() => goToResult(2)}
              >
                Milik Orang Lain
              </Button>
              <Button
                style={{
                  color: "white",
                  border: "1px solid #20929D",
                  width: "100%",
                  justifyContent: "left",
                  borderRadius: "16px",
                  backgroundColor: "transparent",
                }}
                mb={4}
                p={7}
                onClick={() => goToResult(3)}
              >
                Iseng - iseng aja
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Ownership;
