import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import Motor from "../components/img/sport_half.webp";
import Sport from "../components/img/sport.webp";
import Scooter from "../components/img/scooter.webp";
import Trail from "../components/img/trail.webp";
import blurSport from "../components/img/blurSportHalf.webp";

import Navbar from "../components/Navbar";

import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [isColorChanged, setColorChanged] = useState(true);
  const [showSection1, setShowSection1] = useState(true);
  const [changeClass, setChangeClass] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [changeVisible, setChangeVisible] = useState(false);

  const handleButtonClick = () => {
    setColorChanged(!isColorChanged);
    setChangeClass(!changeClass);
    setTimeout(() => {
      setShowSection1(!showSection1);
    }, 200);
  };
  const goToMerk = () => {
    setChangeVisible(!changeVisible);
    setTimeout(() => {
      navigate("/merk");
    }, 1500);
  };
  const sliderStyle = {
    transform: `translateX(calc(-${currentSlide * 100}%`,
    display: "flex",
    transition: "transform 1s ease-in-out",
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3); // Assuming you have 3 images
    }, 2500);

    return () => clearInterval(interval);
  }, []);
  return (
    <Box bgColor={"white"}>
      <Box className={changeVisible ? "fadeout" : ""}>
        {!isColorChanged ? (
          <Navbar
            onClick={() => {
              if (!isColorChanged) {
                handleButtonClick();
              }
            }}
          />
        ) : (
          <Box minH={"10vh"}></Box>
        )}
      </Box>

      <Flex
        pb={"5%"}
        flexDirection={"column"}
        minH={"90vh"}
        justifyContent={"space-between"}
        overflowX={"hidden"}
      >
        {showSection1 && (
          <Box
            className={
              changeClass
                ? "slide-left"
                : changeVisible
                ? "fadeout"
                : "reverse-slide-right"
            }
          >
            <Box px={10}>
              <Text userSelect={"none"} fontSize={30} lineHeight={1} fontWeight={700}>
                Berapa sih harga motormu?
              </Text>
              <Text userSelect={"none"} fontSize={24} fontWeight={400}>
                Yuk, cek harga pasar!
              </Text>
            </Box>
            <Box className="slider-container" maxH={"62vh"} minW={"210vw"}>
              <Box className="slider" style={sliderStyle}>
                <Box className="slide">
                  <Image
                    pointerEvents={"none"}
                    userSelect={"none"}
                    maxW={"100%"}
                    maxH={"62vh"}
                    ml={"-75vw"}
                    objectFit={"contain"}
                    src={Sport}
                    alt="Sport"
                  />
                </Box>
                <Box className="slide">
                  <Image
                    pointerEvents={"none"}
                    userSelect={"none"}
                    ml={"-55vw"}
                    maxW={"100%"}
                    maxH={"62vh"}
                    objectFit={"contain"}
                    src={Scooter}
                    alt="Scooter"
                  />
                </Box>
                <Box className="slide">
                  <Image
                    pointerEvents={"none"}
                    userSelect={"none"}
                    ml={"-50vw"}
                    maxW={"100%"}
                    maxH={"62vh"}
                    objectFit={"contain"}
                    src={Trail}
                    alt="Trail"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {!showSection1 && (
          <Box
            px={10}
            className={changeClass ? "slide-right" : "reverse-slide-left"}
          >
            <Box className={changeVisible ? "fadeout" : ""}>
              <Text userSelect={"none"} fontSize={30} lineHeight={1} fontWeight={700}>
                Pilih jenis, tipe & tahun motormu lalu pilih wilayah
              </Text>
            </Box>
            <Box
              className={changeVisible ? "fadeout" : ""}
              pt={"20%"}
              pb={"30%"}
            >
              <Box className="cardContainer">
                <Card borderRadius={15} className="cards" id="card1"></Card>
                <Card borderRadius={15} className="cards" id="card2"></Card>
                <Card borderRadius={15} className="cards" id="card3">
                  <Image
                    pointerEvents={"none"}
                    userSelect={"none"}
                    minW={"118%"}
                    maxH={"110%"}
                    mr={"-18%"}
                    mb={"-10%"}
                    src={Motor}
                    fallbackSrc={blurSport}
                  />
                </Card>
              </Box>
            </Box>
          </Box>
        )}

        <Flex alignItems={"center"} px={10}>
          <Text
            userSelect={"none"}
            fontSize={"14px"}
            className={changeVisible ? "fadeout" : ""}
            color={"#BABEC1"}
            onClick={() => goToMerk()}
          >
            Lewati
          </Text>
          <Spacer />
          <Icon
            className={changeVisible ? "fadeout icon" : "icon"}
            color={isColorChanged ? "#20929D" : "#D8DCE0"}
            fontSize={10}
            onClick={() => {
              if (!isColorChanged) {
                handleButtonClick();
              }
            }}
            as={BsCircleFill}
            mr={2}
          />
          <Icon
            className={changeVisible ? "fadeout icon" : "icon"}
            onClick={() => {
              if (isColorChanged) {
                handleButtonClick();
              }
            }}
            color={isColorChanged ? "#D8DCE0" : "#20929D"}
            fontSize={10}
            as={BsCircleFill}
            ml={2}
          />
          <Spacer />
          {!changeVisible ? (
            <Button
              onClick={() => {
                if (isColorChanged) {
                  handleButtonClick();
                } else {
                  goToMerk();
                }
              }}
              className="nextButton"
              color={"white"}
              borderRadius={"50px"}
            >
              <Icon as={FaArrowRightLong} />
            </Button>
          ) : (
            <Box w={"48px"} mb={"40px"}>
              <Box className="scaleButton"></Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Home;
