import { Box, Button, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { redirect, useNavigate } from "react-router-dom";
import imageUpdate from "../components/img/updateApps.webp";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar";

const Update = () => {
  const version = useSelector((state) => parseInt(state.data.version, 10));
  const navigate = useNavigate();

  const openGooglePlayStore = () => {
    redirect(
      "https://play.google.com/store/apps/details?id=com.digitalnetworkasia.simotorbeta"
    );
  };
  return (
    <Box height={"100vh"}>
      <Navbar
        onClick={() => navigate("/result")}
        colorIcon={"#3D3F40"}
        bgColor={"white"}
      />
      <Flex
        mt={-2}
        bgColor={"white"}
        h={"91.2vh"}
        flexDirection={"column"}
        justifyContent={"center"}
        p={5}
      >
        <div></div>
        <Grid placeItems={"center"} textAlign={"center"}>
          <Image
            pointerEvents={"none"}
            userSelect={"none"}
            src={imageUpdate}
            maxW={"200px"}
            mb={5}
          />
          <Text
            userSelect={"none"}
            color={"#3D3F40"}
            fontSize={"16px"}
            fontWeight={700}
          >
            Perbarui Aplikasi
          </Text>
          <Text
            userSelect={"none"}
            mt={2}
            color={"#717375"}
            fontSize={"14px"}
            fontWeight={400}
          >
            Aplikasi saat ini belum menyediakan fitur ini, update sekarang untuk
            akses fitur terbaru.
          </Text>
        </Grid>

        {version >= 112 && (
          <Button
            mt={7}
            borderRadius={"10px"}
            width={"100%"}
            onClick={openGooglePlayStore}
            bgColor={"#20929D"}
            color={"white"}
          >
            <Text userSelect={"none"} fontWeight={400} fontSize={"0.8em"}>
              Perbarui Aplikasi
            </Text>
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default Update;
