import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaChevronLeft } from "react-icons/fa6";

function Navbar({ onClick, val, colorIcon, bgColor }) {
  return (
    <div>
      <Flex minH={"10vh"} px={5} alignItems={"center"} bgColor={bgColor}>
        <Icon
          fontSize={"18px"}
          color={colorIcon}
          as={FaChevronLeft}
          onClick={onClick}
        />
        <Text userSelect={"none"} fontSize={"20px"} color={colorIcon} pl={5}>
          {val}
        </Text>
      </Flex>
    </div>
  );
}

export default Navbar;
